<template>
  <div>
    <p>
      <u>Nexus Tower</u>
      <br>•	Nexus Tower is now complete! You can travel there by going through the gate past Kurt Tussel in the Race Place in Nimbus Plaza or from Crux Prime.
      <br>•	Meet each of the Faction Leaders in Nexus Tower.
      <br>•	Nexus Tower introduces many new missions, including Faction Missions.
      <br>•	42 new Achievements have been added for even more challenge!
      <br>•	13 new vendors have been added to Nexus Tower, with new items for sale.
      <br>•	Over 123 new items and over 115 new models have been added including
      <br>    o	Valiant Weapons!
      <br>    o	3 New Rockets
      <br>    o	Faction Pets
      <br>    o	Exclusive Contest Rewards
    </p>
    <p>
      <u>Nimbus Plaza</u>
      <br>•	The Crux Prime launcher in Nimbus Plaza has been moved to Nexus Tower.
    </p>
    <p>
      <u>Crux Prime</u>
      <br>•	Players in Crux Prime can now view the completed tower on the horizon.
      <br>•	Players leaving Crux Prime will return to Nexus Tower.
    </p>
    <p>
      <u>Mail</u>
      <br>•	Players that have unread mail from Customer Service will now receive a notification when they log in.
    </p>
    <p>
      <u>Rocket Launcher</u>
      <br>•	You can now press shift to interact with a rocket launcher. Your minifigure will automatically equip the first rocket in your backpack.
    </p>
    <p>
      <u>Items</u><br>•	The Dragon Helm MkII is now visible.
      <br>•	New 6 and 12 month membership rewards are available.
    </p>
    <p>
      <b>Faction Kit Changes</b>
    </p>
    <p>
      <u>All Kits:</u>
      <br>•	Imagination costs for skills have been rebalanced.
    </p>
    <p>
      <u>The Sentinels:</u>
      <br>
      <br>•	General
      <br>    o	The cooldown on the Strengthen armor ability has been greatly reduced.
      <br>    o	Using strengthen armor now makes nearby enemies more angry with the Sentinel player.
      <br>    o	All Knight and Samurai weapons now make enemies more angry than normal when hit with the weapon. This makes getting an enemy’s attention in a group battle easier.
    </p>
    <p>
      •	Knight
      <br>    o	Rank 1 weapon has had its damage increased.
      <br>    o	Shield slam 1 has had its damage increased to 5.
      <br>    o	Shield Slam 2 has had its damage increased to 10.
      <br>    o	Round House has had its damage increased to 10 and its cooldown reduced to 10 seconds.
    </p>
    <p>
      •	Samurai
      <br>    o	Flaming Arrow 1 sets enemies ablaze dealing 5 damage over 5 seconds.
      <br>    o	Flaming Arrow 2 sets enemies ablaze dealing 10 damage over 5 seconds.
      <br>    o	Flaming Arrow 1 and 2 have had their cooldowns changed to 8 seconds.
      <br>    o	Ronin Rush has had its damage increased to 12 and hits multiple enemies near the point of impact.
      <br>    o	Ronin Rush Has had its cooldown changed to 10 seconds.
      <br>    o	Rank 3 Samurai weapon has had its damage reduced by 1 to match the damage of the knight weapon. This damage loss is balanced out by the increase of damage on the Flaming Arrow 2 and Ronin Rush Skills.
    </p>
    <p>
      <u>The Assembly:</u>
    </p>
    <p>
      •	General
      <br>    o	Assembly weapons are now 3 hit combo weapons that deal 1+2+2 and rank 1, 2+2+3 at rank 2 and 2+3+3 at rank 3.
    </p>
    <p>
      •	Engineer
      <br>    o	All three Engineer Turrets now deal 1 damage per shot but fire at a faster rate and stay in the world longer after being deployed.
      <br>    o	Personal fortress now deals up to 16 damage to enemies in front of the player, has had its close range widened to more reliably hit players near the player and has had its cooldown reduced from 120 seconds to 30 seconds.
    </p>
    <p>
      •	Summoner
      <br>    o	The life of the Summoner Bulpers has been increased to allow them to survive a bit longer.
      <br>    o	Both Summoner Thwoks have had their ability to anger enemies increased and they now stay in the world longer after being summoned.
      <br>    o	Summon Scratch now deals up to 16 damage to nearby enemies and has had its cooldown reduced from 120 seconds to 30 seconds.
    </p>
    <p>
      <u>Venture League:</u>
    </p>
    <p>
      •	General
      <br>    o	Venture League weapons have been changed to deal 1+2+2+3 at rank 1, 2+2+3+3 at rank 2 and 2+2+3+4 at rank 3.
      <br>    o	Super Jump no longer flings the player forward. It now flings them straight up and slams them down dealing 4 damage at rank 1, 6 damage at rank 2 and 8 damage at rank 3
    </p>
    <p>
      •	Buccaneer
      <br>    o	Damage of the Cutlass and Pistol charge up shot has been increased to 5 at rank 1, 7 at rank 2 and 10 at rank 3.
      <br>    o	Full Steam Ahead has had its duration change to 9 seconds at rank 1, 12 seconds at rank 2 and 15 seconds at rank 3.
      <br>    o	Funky Monkey now deals 5 damage to nearby enemies when it poofs away.
    </p>
    <p>
      •	DareDevil
      <br>    o	Flare Guns charge up shot now sets enemies around the point of impact ablaze and deals 3 damage over 1.5 seconds at rank 1, 5 damage over 2.5 seconds at rank 2 and 8 damage over 4 seconds at rank 3.
      <br>    o	Fig on Fire duration has been changed to 9 seconds at rank 1, 12 seconds at rank 2 and 15 seconds at rank 3.
      <br>    o	Fire Extinguisher has had its damage increased to 14 seconds and freezes enemies in place for 5 seconds. Cooldown has been changed to 20 seconds.
    </p>
    <p>
      <u>Paradox:</u>
    </p>
    <p>
      •	General
      <br>    o	The weapon charge up for Paradox weapons no longer takes life to use.
      <br>    o	Paradox weapon damage has been changed to 1+2+2 at rank 1, 2+2+2 at rank 2 and 2+3+3 at rank 3.
    </p>
    <p>
      •	Space Marauder
      <br>    o	Plasma Ball damage has been increased to 6 at rank 1, 8 at rank 2 and 10 at rank 3. Cooldown is now 5 seconds.
      <br>    o	Maelstrom Rocket now does 7 damage at rank 2 and 9 damage at rank 3. Cooldown is now 5 seconds.
      <br>    o	Cluster Strike now releases 8 plasma balls that deal 4 damage each.
    </p>
    <p>
      •	Sorcerer
      <br>    o	Wand Mastery shots now track enemies making it easier to hit enemies that are moving.
      <br>    o	Maelstrom Breath now deals infects enemies with an ailment that deals 8 damage over 4 seconds at rank 1, 12 damage of 4 seconds at rank 2 and 16 damage over 4 seconds at rank 3.
      <br>    o	Dark Thunder now deals 8 damage and a knockback at rank 2 and 10 damage and a knockback at rank 3. Cooldown for these skills is now 6 seconds.
      <br>    o	Last Blast deals 13 damage to nearby enemies while restoring 30 Imagination to nearby teammates.
    </p>
    <p>
      <u>Building</u>
    </p>
    <p>
      •	Several changes have been made to help prevent models from disappearing for players.
    </p>
  </div>
</template>
